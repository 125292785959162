import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import NetworkContext from '../context/network-context';
import { addBscChain, currentProviderIsMetamask } from '../web3/web3';


const WrongNetwork = () => {
    const isMetamask = currentProviderIsMetamask();
    return isMetamask ?
        <Button onClick={() => addBscChain()} className="rounded-pill">Switch to BSC network</Button> :
        <Button disabled variant="outline-warning" className="rounded-pill">Please change to BSC network</Button>;
};


const Connect = ({ connect }) => {
    return <Button
        onClick={connect} className=" text-white">
        Connect Wallet</Button>;
};

const Account = (account, disconnect) => <>
    <h5 className="mb-0 address-text text-center text-lg-right ">{account.slice(0, 6) + '...' + account.slice(-10)}</h5>
    <span role="button" tabIndex={0} onKeyPress={disconnect} onClick={disconnect} className="disconnect-icon-wrapper clickable">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
        </svg>
    </span>

</>;

function ConnectWallet() {
    const { isBsc, account, connected, unlocked, connect, disconnect } = useContext(NetworkContext);
    let child;
    if (!unlocked || !connected || !account) {
        child = Connect({ connect });
    } else if (unlocked && !isBsc) {
        child = WrongNetwork();
    } else {
        child = Account(account, disconnect);
    }

    return (
        <div className="d-flex align-items-center">
            {child}
        </div>
    );
}

export default ConnectWallet;
