import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle, faCopy } from '@fortawesome/free-regular-svg-icons';
import { faRocket, faTractor, faPlus, faWrench, faPalette, faFrog, faProjectDiagram, faFileContract, faExternalLinkAlt, faExclamationCircle, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faYoutube, faMedium, faInstagram, faTwitterSquare, faTelegram, faReddit } from '@fortawesome/free-brands-svg-icons';

let faDiscord = {
    prefix: 'fac',
    iconName: 'discord',
    icon: [512, 512, [], 'e001', 'm349.91062,281.95533c0,16.98235 -12.18,30.90228 -27.60799,30.90228c-15.15733,0 -27.60799,-13.91997 -27.60799,-30.90228s12.18,-30.90228 27.60799,-30.90228c15.42798,0 27.60799,13.91997 27.60799,30.90228zm-126.40127,-30.90228c-15.42799,0 -27.608,13.91997 -27.608,30.90228s12.45066,30.90228 27.608,30.90228c15.42798,0 27.60799,-13.91997 27.60799,-30.90228c0.27065,-16.98234 -12.18,-30.90228 -27.60799,-30.90228zm285.82388,-176.22654c-1.59605,124.05163 -2.08711,256.05906 -0.36823,378.97414c0.48789,40.98803 -35.12022,54.69902 -68.88661,53.63493l-348.92498,1.13654c-30.58532,0 -55.48665,-25.6127 -55.48665,-57.3502l0,-376.39543c0,-31.73746 24.90131,-57.3502 55.48665,-57.3502l362.69316,0c30.58531,0 55.48665,25.6127 55.48665,57.3502l0.00002,0.00002l-0.00001,0zm-77.13997,263.92226c0,-89.64448 -38.976,-162.30661 -38.976,-162.30661c-38.976,-30.06708 -76.05732,-29.23189 -76.05732,-29.23189l-3.78933,4.45438c46.01332,14.47674 67.39598,35.35667 67.39598,35.35667c-64.29492,-36.24515 -139.82042,-36.2517 -202.18792,-8.07359c-10.01467,4.73277 -15.96933,8.07359 -15.96933,8.07359s22.46532,-21.99352 71.1853,-36.47028l-2.70667,-3.34077s-37.08131,-0.8352 -76.05731,29.23189c0,0 -38.97601,72.66214 -38.97601,162.30661c0,0 22.73599,40.36785 82.55328,42.31665c0,0 10.01467,-12.52796 18.13467,-23.10711c-34.37465,-10.57915 -47.36666,-32.85108 -47.36666,-32.85108c3.98177,2.86664 10.54755,6.58261 11.09732,6.95997c45.68557,26.31523 110.57998,34.93689 168.89592,9.74396c9.47333,-3.6192 20.02934,-8.90876 31.12666,-16.42555c0,0 -13.53335,22.82873 -48.99064,33.12947c8.11999,10.57915 17.864,22.55031 17.864,22.55031c59.8173,-1.9488 82.82398,-42.31665 82.82398,-42.31665l0.00002,0.00002l0.00004,0z']
};

library.add(faRocket, faYoutube, faWrench, faInstagram, faExclamationCircle, faTwitter, faCopy, faQuestionCircle, faPlus, faTractor, faCoins, faPalette, faDiscord, faExternalLinkAlt, faReddit, faFrog, faProjectDiagram, faFileContract, faMedium, faTwitterSquare, faTelegram);