/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import '../utils/fontawesome';
import Footer from "./footer";
import Navbar from "./navBar";

const Layout = ({ children, pageInfo }) => 
{
  
  return (<StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title,
            header { 
              mainTitle,
              subtitle
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="main-site-content">
          <Container fluid className="px-0 main">
            <Navbar pageInfo={pageInfo} />
      
            <main>{children}</main>

            <Footer />
          </Container>
        </div>

      </>
    )}
  />)
    };

export default Layout;
