import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, NavItem, NavLink } from "react-bootstrap";
import Icon2 from '../images/fr_2.png';
import Icon3 from '../images/fr_3.png';
import Icon5 from '../images/fr_5.png';

import Icon7 from '../images/fr_7.png';
import Icon8 from '../images/fr_8.png';
import Logo from '../images/logo.png';
import ConnectWallet from "./connect-wallet";
import { debounce } from './helpers';


const CustomNavbar = ({ pageInfo }) => {
	const [expanded, setExpanded] = useState(false);

	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);

	const handleScroll = debounce(() => {
		const currentScrollPos = window.pageYOffset;
		setVisible(currentScrollPos);
		setPrevScrollPos(currentScrollPos);
	}, 100);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);

	}, [prevScrollPos, visible, handleScroll]);
	return (
		<>
			<div className="navbar-wrapper">

				<Navbar expand="lg" variant="dark" id="site-navbar" expanded={expanded} className={visible > 10 ? 'fixed-top' : ''}>
					<Container>
						<AnchorLink to="/">
							<Navbar.Brand>
								<img src={Logo} alt="HedgePay Crypto Logo DeFi" />
							</Navbar.Brand>
						</AnchorLink>
						{/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" onClick={() => setExpanded(!expanded)} /> */}
						<Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
							<Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
								<AnchorLink to="/#how-it-works">
									<Nav.Link as="span" eventKey="how" className=" nav-link-grow-up">
										How it Works
									</Nav.Link>
								</AnchorLink>

								<AnchorLink to="/#economics">
									<Nav.Link as="span" eventKey="economics" className=" nav-link-grow-up">
										Economics
									</Nav.Link>
								</AnchorLink>

								<AnchorLink to="/#roadmap">
									<Nav.Link as="span" eventKey="roadmap" className=" nav-link-grow-up">
										Upcoming Features
									</Nav.Link>
								</AnchorLink>

								<AnchorLink to="/#about">
									<Nav.Link as="span" eventKey="about" className=" nav-link-grow-up">
										About Us
									</Nav.Link>
								</AnchorLink>

								<NavDropdown title="Applications" id="nav-dropdown">
									<NavDropdown.Item eventKey="applications">
										<AnchorLink to="/invest/" className="app-icon">
											<img src={Icon3} alt="Hedge Pay Crypto Finance" />
											<p>Invest</p>
										</AnchorLink>
									</NavDropdown.Item>

									<NavDropdown.Item eventKey="applications">
										<AnchorLink to="/rewards/" className="app-icon">
											<img src={Icon5} alt="Hedge Pay Crypto Finance" />
											<p>Rewards</p>
										</AnchorLink>
									</NavDropdown.Item>

									<NavDropdown.Item eventKey="applications">
										<AnchorLink to="/vaults/" className="app-icon">
											<img src={Icon7} alt="Hedge Pay Crypto Finance" />
											<p>Vaults</p>
										</AnchorLink>
									</NavDropdown.Item>

									<NavDropdown.Item eventKey="applications">
										<AnchorLink to="/ido/" className="app-icon">
											<img src={Icon8} alt="Hedge Pay Crypto Finance" />
											<p>H-Pad</p>
										</AnchorLink>
									</NavDropdown.Item>

									<NavDropdown.Item eventKey="applications">
										<AnchorLink to="/transfer/" className="app-icon">
											<img src={Icon2} alt="Hedge Pay Crypto Finance" />
											<p>Transfers</p>
										</AnchorLink>
									</NavDropdown.Item>
								</NavDropdown>


							</Nav>
						</Navbar.Collapse>
						<div className="d-lg-flex ml-3 align-items-center">
							<ConnectWallet></ConnectWallet>
						</div>

					</Container>
				</Navbar>
				{/* Bottom Tab Navigator*/}
				<nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav " role="navigation">
					<Nav className="w-100">
						<div className=" d-flex flex-row justify-content-around w-100">

							<AnchorLink to="/invest/" className="app-icon-bottom">
								<img src={Icon3} alt="Hedge Pay Crypto Finance" />
								<p>Invest</p>
							</AnchorLink>

							<AnchorLink to="/rewards/" className="app-icon-bottom">
								<img src={Icon5} alt="Hedge Pay Crypto Finance" />
								<p>Rewards</p>
							</AnchorLink>

							<AnchorLink to="/vaults/" className="app-icon-bottom">
								<img src={Icon7} alt="Hedge Pay Crypto Finance" />
								<p>Vaults</p>
							</AnchorLink>

							<AnchorLink to="/ido/" className="app-icon-bottom">
								<img src={Icon8} alt="Hedge Pay Crypto Finance" />
								<p>H-Pad</p>
							</AnchorLink>

							<AnchorLink to="/transfer/" className="app-icon-bottom">
								<img src={Icon2} alt="Hedge Pay Crypto Finance" />
								<p>Transfers</p>
							</AnchorLink>

						</div>
					</Nav>
				</nav>
			</div>
		</>
	);
};

export default CustomNavbar;
