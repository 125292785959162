import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Logo from '../images/logo.png';
import Telegram from '../images/inline/telegram-icon.inline.svg';
import Weechat from '../images/inline/weechat.inline.svg';
import Whatsapp from '../images/inline/whatsapp.inline.svg';
import Discord from '../images/inline/discord.inline.svg';
import Instagram from '../images/inline/instagram.inline.svg';
import Medium from '../images/inline/medium.inline.svg';
import Git from '../images/inline/git.inline.svg';
import Reddit from '../images/inline/reddit.inline.svg';
import Twitter from '../images/inline/twitter.inline.svg';
import Facebook from '../images/inline/facebook.inline.svg';
import Mail from '../images/inline/mail.inline.svg';
import Linkedin from '../images/inline/linkedin.inline.svg';
import Weibo from '../images/inline/weibo.inline.svg';
import Linktree from '../images/inline/linktree.inline.svg';


const Footer = ({ siteTitle, header }) => {

  return (
    <footer className="footer">
      <Container>
        <div className="footer-inner mt-5">
          <Row className="justify-content-between">
            <Col md={3} className="mb-md-0 mb-5">
              <img src={Logo} alt="HedgePay Crypto Defi Logo" />
              <h5>HedgePay</h5>
              <p>Perpetual DeFi powered payroll. <br />Boost your financial security with continuous earning opportunities.</p>
            </Col>
            <Col md={8}>
              <Row>
                <Col sm={4} xs={6} className="mb-sm-0 mb-4">
                  <h6>Resource</h6>
                  <ul>
                    <li><a href="https://docs.google.com/viewer?url=https://raw.githubusercontent.com/hedgepaybsc/files/main/Mission%20Statement%20EN.pdf" target="_blank" rel="noopener noreferrer">Whitepaper</a> |  <a href="https://docs.google.com/viewer?url=https://raw.githubusercontent.com/hedgepaybsc/files/main/Mission%20Statement%20%E4%B8%AD%E6%96%87.pdf" target="_blank" rel="noopener noreferrer">中文</a> </li>
                    <li><a href="https://t.me/hedgepaychina/76" target="_blank" rel="noopener noreferrer">Word from CEO</a></li>
                    <li><a href="https://bscscan.com/address/0xc75aa1fa199eac5adabc832ea4522cff6dfd521a/" target="_blank" rel="noopener noreferrer">Smart Contracts</a></li>
                    <li><a href="https://docs.hedgepay.org/help/faq/" target="_blank" rel="noopener noreferrer">Team</a></li>
                    <li><a href="/terms" target="_blank" rel="noopener noreferrer">Terms Of Service</a></li>
                    <li><a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>

                  </ul>
                </Col>
                <Col sm={4} xs={6} className="mb-sm-0 mb-4">
                  <h6>About Us</h6>
                  <ul>
                    <li><AnchorLink to="/#how-it-works">How it works</AnchorLink></li>
                    <li><AnchorLink to="/#economics">Economics</AnchorLink></li>
                    <li><AnchorLink to="/#roadmap">Upcoming Features</AnchorLink></li>
                    <li><AnchorLink to="/#about">About Us</AnchorLink></li>
                    <li><AnchorLink to="/invest">Invest</AnchorLink></li>
                    <li><AnchorLink to="/rewards">Rewards</AnchorLink></li>
                  </ul>
                </Col>
                <Col sm={4} xs={6} className="mb-sm-0 mb-4">
                  <h6>Connect</h6>
                  <div >
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Whatsapp</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://chat.whatsapp.com/FfqkTfxSWyy1s2rdX3qrOR">
                        <Whatsapp className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Telegram</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://t.me/hedgepay">
                        <Telegram className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Wechat</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="/wechat">
                        <Weechat className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Discord</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://discord.com/invite/fMd4eUbRzt">
                        <Discord className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Twitter</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/HedgePay_">
                        <Twitter className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Facebook</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/hedgepay">
                        <Facebook className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Medium</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@hedgepay">
                        <Medium className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Instagram</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/hedgepay_">
                        <Instagram className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Git</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://github.com/hedgepaybsc">
                        <Git className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Reddit</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/user/hedgepay">
                        <Reddit className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Linkedin</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hedgepay">
                        <Linkedin className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Linktree</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://linktr.ee/hedgepay">
                        <Linktree className="footer-icon" />
                      </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Weibo</strong>.
                        </Tooltip>
                      }>
                      <a target="_blank" rel="noopener noreferrer" href="https://weibo.com/hedgepay">
                        <Weibo className="footer-icon" />
                      </a>
                    </OverlayTrigger>


                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-Whatsapp`}>
                          <strong>Mail</strong>.
                        </Tooltip>
                      }>
                      <a href="mailto:dev@hedgepay.org">
                        <Mail className="footer-icon" />
                      </a>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={8}>
              <p style={{ fontSize: 12, lineHeight: 1.3, marginTop: '4rem' }}><sup>1</sup> Salary is a term utilized by HedgePay to refer to BUSD rewards generated from the HedgeFi Fund, meant to be collected monthly by investors. This in no way indicates any form of employment from HedgePay nor any withstanding responsibility from any parties.</p>
            </Col>
          </Row>
        </div>

        <div className="copyright text-center"> © Copyright 2021</div>
      </Container >
    </footer >
  );
};

export default Footer;
